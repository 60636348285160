<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Tipo de Cobertura')}}</h3>
        </div>		
		<div class="p-col-4">
			<div class="p-grid">
				<div class="p-col-12">
					<div class="card">
						<h4 class="p-m-0">{{$t('Fire Insurance')}}</h4><br>
						<DataTable :value="products1" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers" dataKey="id">
							<Column field="name" :header="$t('Nombre')" :sortable="true">
								<template #body="slotProps">
									<div v-if="i18n.locale() == 'es'">
										{{slotProps.data.nombre}}
									</div>
									<div v-else>
										{{slotProps.data.name}}
									</div>
								</template>
							</Column>
							<Column field="activo" :header="$t('Estatus')" headerStyle="width: 100px">
								<template #body="slotProps">
									<InputSwitch v-model="slotProps.data.activo" />
								</template>
							</Column>
						</DataTable>
					</div>
				</div>
				<div class="p-col-12">
					<div class="card">
						<h4 class="p-m-0">{{$t('Health Insurance')}}</h4><br>
						<DataTable :value="products3" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers" dataKey="id">
							<Column field="name" :header="$t('Nombre')" :sortable="true">
								<template #body="slotProps">
									<div v-if="i18n.locale() == 'es'">
										{{slotProps.data.nombre}}
									</div>
									<div v-else>
										{{slotProps.data.name}}
									</div>
								</template>
							</Column>
							<Column field="activo" :header="$t('Estatus')" headerStyle="width: 100px">
								<template #body="slotProps">
									<InputSwitch v-model="slotProps.data.activo" />
								</template>
							</Column>
						</DataTable>
					</div>
				</div>	
				<div class="p-col-12">
					<div class="card">
						<h4 class="p-m-0">{{$t('Business Insurance')}}</h4><br>
						<DataTable :value="products4" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers" dataKey="id">
							<Column field="nombre" :header="$t('Nombre')" :sortable="true">
								<template #body="slotProps">
									<div v-if="i18n.locale() == 'es'">
										{{slotProps.data.nombre}}
									</div>
									<div v-else>
										{{slotProps.data.name}}
									</div>
								</template>
							</Column>
							<Column field="activo" :header="$t('Estatus')" headerStyle="width: 100px">
								<template #body="slotProps">
									<InputSwitch v-model="slotProps.data.activo" />
								</template>
							</Column>
						</DataTable>
					</div>
				</div>
			</div>
		</div>
		<div class="p-col-8">
			<div class="card">
				<h4 class="p-m-0">{{$t('Vehicle Insurance')}}</h4><br>
				<DataTable :value="products2" v-model:expandedRows="expandedRows" dataKey="id"
					@rowExpand="onRowExpand" @rowCollapse="onRowCollapse" responsiveLayout="scroll">
					<Column :expander="true" headerStyle="width: 3rem" />					
					<Column field="name" :header="$t('Nombre')" :sortable="true" headerStyle="width: 150px">
						<template #body="slotProps">
                            <div v-if="i18n.locale() == 'es'">
                                {{slotProps.data.nombre}}
                            </div>
                            <div v-else>
                                {{slotProps.data.name}}
                            </div>
						</template>
					</Column>
					<Column field="responsabilidad" :header="$t('Responsabilidad')" :sortable="true" headerStyle="width: 200px">
						<template #body="slotProps">
							<InputNumber v-model="slotProps.data.responsabilidad" min="0" mode="currency" :currency="$store.state.currency"/>
						</template>
					</Column>
					<Column field="foto" :header="$t('Foto Vehículo')" headerStyle="width: 120px">
						<template #body="slotProps">
							<InputSwitch v-model="slotProps.data.foto" />
						</template>
					</Column>
					<Column field="activo" :header="$t('Estatus')" headerStyle="width: 80px">
						<template #body="slotProps">
							<InputSwitch v-model="slotProps.data.activo" />
						</template>
					</Column>
					<template #expansion="slotProps">
						<div class="orders-subtable">
							<DataTable :value="slotProps.data.opciones" responsiveLayout="scroll">
								<Column field="tipocliente" :header="$t('Dirigido a')">
									<template #body="slotProps">
										<div v-if="i18n.locale() == 'es'">{{slotProps.data.nombre}}</div>
										<div v-if="i18n.locale() == 'en'">{{slotProps.data.name}}</div>
									</template>
								</Column>
								<Column field="limite" :header="$t('Factor')">
									<template #body="slotProps">
										<InputText v-model="slotProps.data.limite" type="number" min="0" style="max-width:100px;"/>
									</template>
								</Column>
								<Column field="tarifa" :header="$t('Tarifa')">
									<template #body="slotProps">
										<Dropdown v-model="slotProps.data.tarifa" :options="valores" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
									</template>
								</Column>
							</DataTable>
						</div>
					</template>
				</DataTable>

			</div>
		</div>
		<div class="p-col-12">
			<div class="card">
                <Button :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/ApiConsulta";
    import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		data() {
			return {
                i18n: null,
				insert: false,
				edit: false,
				del: false,
				products1: [],
				products2: [],
				products3: [],
				products4: [],
				valores: [],
				productDialog: false,
				permisosDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				permisos: {},
				filters: {},
				submitted: false,
				size: '60vw',
				niveles: null,
				expandedRows: [] 
			}
		},
		productService: null,
		created() {
            this.i18n = useI18nPlugin();
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.products1 = [];
				this.products2 = [];
				this.products3 = [];
				this.$store.commit('Loading');
				const Consulta = new API('Configuracion');
				Consulta.Ini('TipoCobertura').then(result => {
					//this.$store.state.error = result.consult.tipos2;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.products1 = result.consult.tipos1;
					this.products2 = result.consult.tipos2;
					this.products3 = result.consult.tipos3;
					this.products4 = result.consult.tipos4;
					this.valores = result.consult.valores;
					if(result.consult.tipos2){
						for (var i = 0; i < this.products2.length; i++) {
							for (var j = 0; j < this.products2[i].opciones.length; j++) {
								this.valores.forEach(element => {
									if(this.products2[i].opciones[j].tarifa==element.code){
										this.products2[i].opciones[j].tarifa = element;
									}
								});  
							}	
						}					
					}
					this.$store.commit('Loading');
				}); 
			},
			saveProduct() {
                this.$store.commit('Loading');
				const Consulta = new API('Configuracion');          
				Consulta.Procesar('TipoCobertura',{
					caso: 	'UPD',
					products1: 	this.products1,
					products2: 	this.products2,
					products3: 	this.products3,
					products4: 	this.products4,
				}).then(response => {
					//this.$store.state.error =response;
					if (response.result) {
                        this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
                    this.$store.commit('Loading');
				});
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
            expandAll() {
                this.expandedRows = this.products.filter(p => p.id);
            },
            collapseAll() {
                this.expandedRows = null;
            },
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
